import { lazy } from "react";
import PGNList from "../pages/PGNList/PGNList";


const LoginComponent = lazy(() => import('./../pages/Login/Login'));

// ADMIN SPECIFIC SCREENS
const HomeComponent = lazy(() => import('./../pages/HomeScreen/HomeScreen'));
const EnrollmentComponent = lazy(() => import('./../pages/Enrollments/Enrollments'));
const MastersComponent = lazy(() => import('./../pages/Masters/Masters'));
const DashboardComponent = lazy(() => import('../pages/Dashboard/Dashboard'));
const PNGComponent = lazy(() => import('../pages/PGNList/PGNList'));

// STUDENT SPECIFIC SCREENS
const StudentHomeComponent = lazy(() => import('./../pages/StudentHome/StudentHome'));
const GameListComponet = lazy(() => import('./../pages/GameList/GameList'));
const GamePGN = lazy(() => import('../pages/Game/Game'));
const AssignmentPGN = lazy(() => import('../pages/Assignment/Assignment'));
const RevisionMaterialPGN = lazy(() => import('../pages/RevisionMaterial/RevisionMaterial'));

export const unRestrictedRoutes = [{
    path: '/',
    exact: true,
    component: LoginComponent
}]

// ADMIN --> 616d6412f3211f77d30bdc52
// STUDENT --> 616d6437f3211f77d30bdc54

export const restrictedRoutes: any = {
    '': [],
    '616d6412f3211f77d30bdc52': [
        {
            path: '/home',
            exact: true,
            component: HomeComponent
        }, {
            path: '/enrollments',
            exact: true,
            component: EnrollmentComponent
        }, {
            path: '/master',
            exact: false,
            component: MastersComponent
        }, {
            path: '/dashboard',
            exact: false,
            component: DashboardComponent
        }, {
            path: '/pgn-grid',
            exact: true,
            component: PNGComponent
        }
    ],
    '616d6437f3211f77d30bdc54': [
        {
            path: '/home',
            exact: true,
            component: StudentHomeComponent
        },
        {
            path: '/games/:category',
            exact: true,
            component: GameListComponet
        },
        {
            path: '/games/game/pgn/:assignmentId',
            exact: true,
            component: GamePGN
        },
        {
            path: '/games/assignments/pgn/:assignmentId',
            exact: true,
            component: AssignmentPGN
        },
        {
            path: '/games/revision/pgn/:assignmentId',
            exact: true,
            component: RevisionMaterialPGN
        },
    ]
}