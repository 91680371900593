import { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { unRestrictedRoutes, restrictedRoutes } from './utility/routes';



const App = () => {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let role = localStorage.getItem('role') || '';
  // console.log('role', role);

  localStorage.setItem('role', role);


  const checkToken = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      history.replace('/');
      localStorage.clear();
      role = '';
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }


  useEffect(() => {

    console.log(unRestrictedRoutes);
    

    checkToken();
    history.listen(l => {
      if (l.pathname !== '/') {
        checkToken();
      }
      else
      {
        setIsLoggedIn(false);
        role = '';
      }
    });
  }, []);

  return <>
    
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>

        {
          unRestrictedRoutes?.map(ur =>
            <Route
              key={ur.path}
              exact={ur.exact}
              path={ur.path}
              component={ur.component}
            ></Route>
          )
        }

        {
          isLoggedIn && restrictedRoutes[role]?.map((r: any) =>
            <Route
              key={r.path}
              exact={r.exact}
              path={r.path}
              component={r.component}
            ></Route>)
        }

        {
          isLoggedIn && <Route path="*">
            <Redirect to="/home" />
          </Route>
        }

      </Switch>
    </Suspense>
    <ToastContainer />

  </>;
}

export default App;
